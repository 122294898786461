<template>
	<div>
		<p class="m-right-title">学习课程</p>
		<div class="m-video-right-list">
			<template v-for="(item,index) in courseList">
				<div class="courseClass1 u-m-t-25">
					<span>{{item.name}}</span>
				</div>
				<div v-for="(item2,index2) in item.son" :title="item2.name" class="m-video-right-item" @click="to_link('/center/test3',{course_id:course_id,cate_id:cate_id,type_id:item2.id})">
					<div class="u-flex">
						<span class="m-video-right-item-text u-line-1">{{item2.name}}</span>
					</div>
					 
					<div>
						<span class="all_son">共{{item2.son_count}}题</span><a class="lianxi_btn" href="javascript:void(0);">开始练习</a>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		to_link
	} from "@/router/api"
	import {
		getQuestionCate
	} from "@/api/center.js"
	export default {
		data() {
			return {
				loading: false,
				total: 0,
				course_id: '',
				cate_id: '',
				courseList: [],
			}
		},
		mounted() {
			this.course_id = this.$route.query.course_id
			this.cate_id = this.$route.query.cate_id
			this.getCate()
		},
		methods: {
			to_link,
			async getCate() {
				let res = await getQuestionCate({
					course_id: this.course_id
				})
				if (res.code == 200) {
					this.courseList = res.data
				} else {
					this.$message.error(res.message)
					this.$router.back()
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.lianxi_btn{
		border:1px solid #666;
		color:#666;
		padding:6px 18px;
		text-decoration: none;
		margin-right: 10px;
	}
	.m-right-title {
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}
</style>

<style lang="scss">
	.all_son{
		color:#aaa;
		padding:0 10px;
	}
	.m-item-con {
		.el-progress-bar__inner {
			background-color: #D6102A;
		}
	}

	.courseClass1 {
		display: flex;
		align-items: center;
		position: relative;
		height: 44px;
		background: #f2f2f2;
		color: #d6102a;
		font-size: 16px;
		padding-left: 16px;

		&::before {
			content: '';
			width: 3px;
			height: 25px;
			background: #42acdc;
			border-radius: 3px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.m-video-right-list {
		display: flex;
		flex-direction: column;
		padding: 16px 16px 24px 24px;

		.m-video-right-item {
			display: flex;
			height: 44px;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			border-bottom: 1px solid #eee;
			margin-left: 15px;

			.m-video-right-item-text {
				width: 600px;
				font-size: 14px;
				color: #2D2D2D;
			}

			.m-video-right-item-time {
				display: flex;
				align-items: center;
				flex: 1;
				justify-content: flex-end;
				font-size: 14px;
				color: #838383;
			}
		}

		.m-video-item-active {
			.m-video-right-item-text {
				color: #D6102A !important;
			}

			.m-video-right-item-time {
				color: #D6102A !important;
			}
		}
	}
</style>
